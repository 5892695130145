<template>
  <el-card class="main-card">
    <template #header>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">快递业务</el-breadcrumb-item>
        <el-breadcrumb-item>导入收费标准</el-breadcrumb-item>
      </el-breadcrumb>
    </template>

    <div v-if="!scales && !id" class="storedialog load-dialog" style="text-align: center">
      <div class="download" style="margin: 10px auto">
        点击下载模板：
        <a target="_blank" :href="templateUrl"> 快递收费标准导入模板.xlsx </a>
      </div>

      <el-upload v-loading="loading" class="upload-box" ref="upload" action="#" :http-request="submitUpload" :auto-upload="true" drag :limit="1">
        <el-icon style="font-size:3em;">
          <UploadFilled />
        </el-icon>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip">
          注：Excel表格里的填写项请严格按照模板内容进行填写
        </div>
      </el-upload>
    </div>

    <div v-if="scales || id">
      <el-form :model="express" ref="express" :rules="rules" class="detail-form" size="small" @submit.prevent>
        <el-row>
          <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6">
            <el-form-item label="快递名称：" prop="expressName">
              <el-col>
                <el-input v-model="express.expressName"> </el-input>
              </el-col>
            </el-form-item>
          </el-col>
          <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6">
            <el-form-item label-width="130px" label="始发城市:" prop="cityName">
              <el-col>
                <el-input v-model="express.cityName"> </el-input>
              </el-col>
            </el-form-item>
          </el-col>
          <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6">
            <el-form-item label-width="130px" label="生效日期:" prop="expressDate">
              <el-col>
                <el-date-picker v-model="express.expressDate" type="date" placeholder="" format="YYYY-MM-DD" style="max-width: 90%"></el-date-picker>
              </el-col>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :xs="18" :sm="18" :md="18" :lg="18" :xl="18">
            <el-form-item label="收费标准：" prop="remark">
              <el-input type="textarea" placeholder="请输入备注内容以及收费标准" v-model="express.remark" maxlength="1000" show-word-limit :rows="4" style="width: 88%"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :xs="18" :sm="18" :md="18" :lg="18" :xl="18"> </el-col>
          <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6" style="text-align: right">
            <el-form-item>
              <el-button type="primary" class="el-icon-check" @click="submitEvent" v-if="!express.id">
                保存
              </el-button>
              <el-button size="small" type="info" class="el-icon-back" @click="back()">
                返回
              </el-button>

            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <el-table class="table" ref="scaleTable" :data="scales" style="width: 100%" border size="small">
        <el-table-column type="index" label="序号" align="center" width="50">
        </el-table-column>
        <!-- <el-table-column prop="expressName" label="快递" sortable width="90"> </el-table-column>
          <el-table-column prop="cityName" label="始发城市" sortable width="100"> </el-table-column> -->
        <el-table-column prop="targetProvince" label="目标省份" sortable width="100">
        </el-table-column>
        <el-table-column prop="targetCity" label="目标城市" sortable width="550">
        </el-table-column>

        <el-table-column prop="feeScale" label="计费标准" width="400">
          <template #default="scope">
            <span class="item" v-for="(item, key) in JSON.parse(scope.row.feeScale)" :key="key">
              {{ item.remark }} ,
              {{ item.price ? "费用：" + item.price + "；" : "" }}
              {{ item.unit ? "续重单价：" + item.unit + "；" : "" }}
              <br v-if="item.index % 2 == 0" />
            </span>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </el-card>
</template>

<script>
import { uploader } from "@/api/subupload";
import { preScale, submitMailScale, fetchExpressDetail } from "@/api/mailscale";
export default {
  name: "scale",
  data() {
    return {
      templateUrl: process.env.VUE_APP_BASEURL + "/static/template/mailscale.xlsx",
      scales: null,
      id: null,

      express: {},
      loading: false,
      rules: {
        expressName: [
          { required: true, message: "请输入快递名称", trigger: "blur" },
        ],
        cityName: [
          { required: true, message: "请输入始发城市", trigger: "blur" },
        ],
        expressDate: [
          { required: true, message: "请输入生效日期", trigger: "blur" },
        ],
        remark: [
          {
            required: true,
            message: "请输入备注内容以及收费标准",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    //上传操作
    submitUpload(file) {
      this.loading = true;
      var dataList = new FormData();
      dataList.append(file.file.name, file.file);
      dataList.append("fileType", "excel");
      dataList.append("dataType", "MailScale");
      this.$refs.upload.clearFiles();

      uploader(dataList)
        .then((res) => {
          if (res.code === 200) {
            this.$message({ message: "上传成功", type: "success" });
            this.dataId = res.data[0].id;
          } else {
            this.$message.error("上传失败，请重试");
          }
        })
        .then(() => {
          this.loading = false;
          preScale(this.dataId).then(
            (res) => {
              if (res.code === 200) {
                console.log(Object.values({ ...res.data }));
                this.scales = res.data;
              }
            },
            (err) => {
              console.log(err);
            }
          );
        });
    },

    submitEvent() {
      let dtos = {
        express: this.express,
        scales: this.scales,
      };

      console.log(dtos);
      let that = this;

      this.$refs.express.validate((valid) => {
        if (!valid) return;

        submitMailScale(dtos).then((res) => {
          that.$message({ type: res.type, message: res.content });
          if (res.code == 200) {
            //that.$router.back(-1);
            that.express = res.data.express;
            that.scales = res.data.scales;
          }
        });
      });
    },

    fetchs(expressId) {
      let that = this;
      fetchExpressDetail(expressId).then((res) => {
        that.express = res.data.express;
        that.scales = res.data.scales;
        that.rules = [];
      });
    },
    //打印
    onprint() {
      print(this.$refs.print);
    },
    back() {
      this.$router.go(-1);
    },
  },
  mounted() {
    this.id = this.$route.query.id;
    if (this.id) this.fetchs(this.id);
  },
};
</script>
<style lang="less">
</style>